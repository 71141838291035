@font-face {
  font-family: DMSans;
  src: url(./fonts/DMSans-Bold.ttf);
}
@font-face {
  font-family: DMSans;
  src: url(./fonts/DMSans-Medium.ttf);
}
@font-face {
  font-family: DMSans;
  src: url(./fonts/DMSans-Regular.ttf);
}
body{
  font-family: DMSans !important;
}

.radiomargin{
  margin-left: 10px;
  font-size: 14px;
  position: relative;
  top: -1.5px;
}
.cancel-text{
  font-size: 14px;
  margin-left: 5px;
}
.cancel-button{
  width:100%;
  margin-left: 10px;
  font-size: 14px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
}
.form-control:focus {
  box-shadow: none !important;
}
.backgroundImage{
  /* background-image: url(./img/background_img.png); */
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-height: 800px;
}
.modal-open{
  /* background-color: rgb(201, 0, 0); */
    color: rgb(255, 255, 255);
    position: absolute;
    top: 100px;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
}
.close {
  /* border: 1px solid #c90000; */
  color: #fff !important;
  margin-bottom: 16px;
  font-size:30px !important ;
}
.modal::-webkit-scrollbar {
  width: 2px !important;
}
.addon-scroll::-webkit-scrollbar {
  width: 2px !important;
}
.addon-scroll::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
.addon-scroll::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.addon-scroll::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
/* Track */
.modal-body::-webkit-scrollbar {
  width: 2px !important;
}
.modal-body::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
.modal-body::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.modal-body::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.modal-body {
  padding: 0 !important;
  overflow-y: overlay;
  overflow-x: hidden;
  height: 440px;
  border-top: none !important;
}
.modal.fade .modal-dialog { 
  transform: translate(0, 0px) !important;
}
.modal-dialog {
  max-width: 600px !important;
}
.modal-header {
  border-bottom: none !important;
  /* background-color: #c90000; */
}
/* .modal-content {
  border-radius: 50px !important;
} */
.main-color{
  color: #c90000 !important  ;
}
.seen_text_main{
  background: #fff!important;
  /* min-height: 500px !important; */
}
.foot-content{
  font-size: 11px;
  font-weight: 500;
  position: relative;
  top: 15px;
}

.p-10 {
  padding-left: 10px !important;
}
.cursorPointer{
  cursor: pointer;
}
.safety-text {
  background-color: #000;
  padding: 12px;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.margin_text{
  padding-bottom: 100px;
}
.calendar-top {
  /* background-color: #4a4445e8; */
  background-color: #000;
  padding: 15px 10px;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #fff;
}
.icons {
  color: white;
  /* background-color: #dbc0c03b; */
  background-color: #c90000;
  border: none;
  font-size: 12px;
  transition: color 0.3s;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  transition: 0.5;
}
.top {
  display: inline-block;
  width: 100%;
  border: 1px solid #767676;
  border-radius: 5px;
  height: 37px;
}
.top h3 {
  color: #fff;
  font-size: 14px;
  padding: 10px;
}
.calendar-bottom {
  background-color: #fff;
  border-bottom: 2px solid #767676;
  border-radius: 5px;
  position: absolute;
  width: 93%;
  z-index: 999 !important;
}
/* calendar css*/
.react-calendar {
  width: 100% !important;
  background: inherit !important;
  color: #fff !important;
}
.react-calendar__tile:disabled {
  background-color: #fff!important;
  color: #ccc !important;
}
/* react-calendar__tile--active {
  background: #c90000 !important;
  color: #fff !important;
} */
/* .react-calendar__tile:disabled abbr {
  border: none !important;
  background-color: #f0f0f0;
  border-radius: 0;
} */
.react-calendar__month-view__days__day--neighboringMonth abbr {
  color: #ccc;
}
button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth abbr {
  border: none !important;
}
button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth abbr:hover {
  background-color: transparent !important;
  cursor: no-drop;
}
button.react-calendar__navigation__arrow {
  height: 30px !important;
  min-width: 30px !important;
  width: 30px !important;
  border: 1px solid !important;
  border-radius: 50%;
  font-size: 20px;
  margin: 5px 5px 0px 5px !important;
}
button:disabled.react-calendar__navigation__arrow {
  border: 1px solid #ccc !important;
}
button.react-calendar__tile.react-calendar__month-view__days__day abbr {
  border: 1px solid !important;
  border-radius: 50%;
  padding: 5px;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 30px;
  max-height: 30px;
}
.react-calendar__tile {
  padding: 2px !important;
  line-height: 20px !important;
}
.disabled {
  display: none;
}
.react-calendar__tile--now abbr {
  /* background: pink !important; */
}
.react-calendar__tile--active {
  background: transparent !important;
  color: white;
}
.react-calendar__tile--now {
  background: transparent !important;
}

.react-calendar__tile--active abbr {
  /* background: inherit !important; */
  /* color: white !important; */
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 36px !important;
  /* height: 40px; */
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
}
.react-calendar__month-view__weekdays {
  color: #000;
}
button.react-calendar__tile.react-calendar__month-view__days__day:disabled {
  border: none !important;
}
button.react-calendar__tile.react-calendar__month-view__days__day:disabled abbr {
  background-color: #fff !important;
}
/* .react-calendar button {
    color: #000;
} */
.react-calendar {
  border-radius: 6px;
}
.react-calendar__viewContainer {
  margin-top: -10px;
  font-size: 12px !important;
}
.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  background-color: transparent !important;
  color: #fff !important;
}
/* button.react-calendar__tile abbr:hover {
  background-color: #000 !important;
  border-color: #fff !important;
} */
.react-calendar__month-view__weekdays {
  font-weight: 700 !important;
}
.react-calendar__viewContainer {
  padding: 0px 10px 5px !important;
}
abbr[title] {
  text-decoration: none !important;
}
button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none !important;
}
button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none !important;
}
button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  /* color: #c90000 !important; */
}
/* button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  color: #c90000 !important;
} */
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
  color: #ccc !important;
}
.FS-16{
  font-size: 16px;
}
button.icons.guests {
  position: relative;
  top: -8px;
}
button.icons.guests_icons {
  position: relative;
  top: -8px;
}

span.guests {
  font-size: 14px;
  font-weight: normal;
}
.fade:not(.show) {
  opacity: 1 !important;
}

.selected-bar {
  background-color: #fff !important;
  color: #000;
  height: 50px;
  font-weight: 400;
  border-radius: 5px;
  font-size: 17px;
  padding: 12px;
  border: 1px solid;
}
.head-icon{
  border: 1px solid ;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 5px;
  font-size: 18px;
  /* color: #c90000; */
}
.bg_dark_text {
  background-color: #c90000;
  color: #000;
  margin-top: 10px;
  border-radius: 5px;
}
.safety_time {
  font-weight: 500;
  font-size: 14px;
}
select { 
  font-weight: 500;
}
.information {
  background-color: #fff;
  padding: 10px;
  color: #000;
}
.information h6 {
  margin-top: 10px;
  color: #000;
}
.form-group {
  margin-bottom: 6px;
}
.form_left {
  border-top-left-radius: 8px;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 1.5 !important;
  background-clip: padding-box !important;
  border-radius: 0px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out ;
}
.summary {
  background-color: #c90000;
  text-align: left;
  padding: 8px 0px 8px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #fff;
}
label.lable_text {
  margin-top: -5px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  position: relative;
    top: 2px;
}
.float-left{
float: left !important;
}
.float-right{
  float: right !important;
  }
.submit-btn{
  width: 50%;
  background-color: #c90000;
  border: 1px solid #c90000;
  color: #fff !important;
  border-radius: 5px;
  height: 35px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
}
.your_text {
  width: 50%;
  border-right: 1px solid #000;
  padding: 10px;
  text-align: center;
}
.your_guests {
  width: 50%;
  border-left: 1px solid #000;
  padding: 10px;
  text-align: center;
}
.css-b62m3t-container {
  color: #000 !important;
}
.modal-title {
  font-size: 19px !important;
}
.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
  background-color: #fff;
}
/* width:"100%",backgroundColor:'#c90000',height: "27px",color:"#fff",border:"none",outline:"none",fontSize:"14px" */
.select-guest-cap{
width:100%;
/* background-color: #c90000; */
height: 35px;
color: #fff;
border: none;
outline: none;
font-size: 14px;
}
.icon-media-spn{
  margin-top: 5px !important;
}
#monthYear svg {
  margin-bottom: 2px !important;
}
.special-outer{
  padding: 10px;
}
/* Media css Goes here*/
@media only screen and (min-width: 1191px) and (max-width: 1440px){
  .dates_other {
  display: block !important;
}
}
@media only screen and (min-width: 768px) and (max-width: 1191px){
  .readmore-position .readmoreText {
    left: 50% !important;
}
  .dates_future button {
    font-size: 12px !important;
    width: 100px !important;
}
  .modal-content {
    position: absolute !important;
    top: 0px !important;
}
.dates_other {
  display: block !important;
}

label.lable_text {
  font-size: 13px;
}
.summary span {
  font-size: 13px;
}

.form-control {
  font-size: 13px !important;
  border-radius: 0px !important;
}

.seen_taking {
  font-size: 13px !important;
}
.safety-text span {
  font-size: 14px !important;
}
.information h6 {
  margin-top: 10px;
}
span.text_guests {
  font-size: 15px;
}

}
@media only screen and (max-width: 991px){
  .bottom_input{
    margin-top: 8px;
  }
  .selected-bar{
    margin-top: 8px !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 768px){
.dates_other {
  display: block !important;
}
span.text_guests {
  font-size: 15px;
}
label.lable_text {
  font-size: 13px;
}
.summary span {
  font-size: 13px;
}

.form-control {
  font-size: 13px !important;
  border-radius: 0px !important;
}
.seen_taking {
  font-size: 13px !important;
}
.safety-text span {
  font-size: 14px !important;
}
.information h6 {
  margin-top: 10px;
}
}
.alert-text{
  font-size: 14px;
  font-weight: 500;
}
@media only screen and (min-width: 320px) and (max-width: 600px){
  p.cancel-text-head {
    font-size: 12px !important;
}
.text-center-mob{
  text-align: start !important;
}
  .addonArrayModal{
    margin-left: 30% !important;
  }
  /* .mobile-menu-addon{
    display: block !important;
  } */
  .list-category li{
    font-size: 11px  !important;
  }
  button.checkout-font {
    /* right: 10px !important; */
    font-size: 13px !important;
}
  .float-right-mob{
    float: none !important;
  }
  .readmore-font {
    font-size: 12px !important;
  }
  .addonName{
    font-size: 13px !important;
  }
  /* .readmoreText {
    bottom: 33px !important;
    left: 92% !important;
  } */
  .cancel-text{
    font-size: 12px;
  }
  .cancel-button{
    font-size: 12px;
  }
  .cancel-text-head{
    font-size: 12px;
  }
  .radiomargin{
    font-size: 11px;
    top: -4px !important;
  }
  .modal-body {
    height: 100% !important;
}
.information h6 {
  margin-top: 0px;
}
  .alert-text{
    font-size: 12px;
  }
  button.react-calendar__navigation__arrow {
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;
  }
  .dates_future button {
    font-size: 12px !important;
    width: 140px !important;
}
  .modal-content {
    position: absolute !important;
    top: 0 !important;
}
  div#mainModal {
    overflow: scroll !important;
}
  .special-outer {
    padding: 5px 10px;
    align-items: center !important;
  }
  .availability svg {
    font-size: 14px;
}
  .select-guest-cap{
    height: 27px;
    font-size: 12px;
    }
  #monthYear svg {
    margin-bottom: 3px !important;
  }
  .icon-media-spn{
    margin-top: 0px !important;
  }
  .top {
      height: 30px;
    }
    .top h3 {
      color: #fff;
      font-size: 12px;
      padding: 5px;
      margin: 0;
      line-height: 18px;
      font-weight: 500;
    }
    .bottom_input {
      height: 30px !important;
      padding: 0px 5px !important;
    }
  .submit-btn {
    width: 75%;
    font-size: 12px;
    font-weight: 500;
  }
  .safety_time {
    font-size: 12px;
  }
  .dates span {
    font-size: 12px !important;
  }
  .foot-content {
    font-size: 9px;
  }
  label.cursorPointer {
    font-size: 12px !important;
  }
  .col-md-8 p.text-center {
    font-size: 12px !important;
    font-weight: 500;
    width: 100%;
    padding: 0px 10px;
  }
  .seen_taking {
    font-size: 12px !important;
  }
label.lable_text {
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 12px;
  text-align: left;
  top: 0px;
}

span.text_guests {
 font-size: 13px !important;
}
.con-book {
  font-size: 10px !important;
}
.confirm-Book {
  width: 90% !important;
  font-size: 12px !important;
}
.selected-bar{
  width: 100% !important;
}
.head-icon {
  border: none;
}
.dates_other button {
  width: 95px !important;
  margin: 5px 5px 5px 5px !important;
  font-size: 12px !important;
}
.form-control{
  border-radius: 5px;
  font-size: 12px !important;
}
}
@media only screen and (min-width: 320px) and (max-width: 374px){

  .dates_other button {
    width: 75px !important;
}
}
.dates {
  background-color: #fff;
  border-bottom: 2px solid #000;
  margin-top: 10px;
}
.dates span {
  font-weight: 500;
  font-size: 14px;
}
.dates_other button:hover {
  background-color: #fff !important;
  color: #000;
  transition: 0.5s;
}
.dates_other {
  display: block !important;
}
.dates_other button {
  height: 35px;
    width: 90px;
  margin: 10px 0px 4px 3px;
  border-radius: 4px;
  /* background-color: #c90000; */
  border: 1px solid;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}
.dates_future button:hover {
  color: #000 !important;
  background-color: #fff !important;
  transition: 0.5s;
}
.dates_future button {
  margin: 5px 5px 5px 5px !important;
  /* background-color: #c90000; */
  color: #fff;
  /* border-color: #c90000; */
  border-radius: 5px;
  height: 35px;
  width: 103px;
  font-size: 14px;
  font-weight: 500;
}
.react-tel-input .form-control {
  width: 100% !important;
}
.bottom_input{
  display: flex;
    justify-content: space-around;
    width: 100%;
    border: 1px solid rgb(118, 118, 118);
    border-radius: 5px;
    height: 37px;
    padding: 0px 10px;
}
/* loader css */
.ripple__rounds {
  border: 0 solid transparent;
  border-radius: 50%;
  position: relative;
  }
  .ripple__rounds:before,
  .ripple__rounds:after {
  content: "";
  border: 0.5em solid rgb(255, 255, 255);
  border-radius: 50%;
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  animation: ripple__rounds 1s linear infinite;
  opacity: 0;
  }
  .ripple__rounds:before {
  animation-delay: 0.5s;
  }
  .ripple__rounds:after {
  animation-delay: 0;
  }
  @keyframes ripple__rounds {
  0% {
  transform: scale(0);
  opacity: 0;
  }
  50% {
  opacity: 1;
  }
  100% {
  transform: scale(1);
  opacity: 0;
  }
  }
  [class*="ripple__rounds"] {
 display: inline-block;
 width: 6em;
 height: 6em;
 color: inherit;
 vertical-align: middle;
 pointer-events: none;
 /* background-color: #c90000; */
}
.box{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #100e0e87;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  }
/* end of loader css */
.con-book{
  font-size: 14px;
}
.confirm-Book{
  /* background-color: #c90000; */
  color: #fff;
  width: 60%;
  border-radius: 5px;
  /* border-color: #c90000; */
  font-size: 16px;
  padding: 10px;
  text-transform: uppercase;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
}
span.text_guests {
  font-size: 15px;
  font-weight: 500;
}
label.cursorPointer {
  font-size: 14px;
  font-weight: 500;
}
/* Added new for addon */
.active-category{
  /* background-color: #2596be;     */
  /* background-color: rgb(65, 105, 225); */
  color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
  width: 60%;
}
.list-category{
  padding-left: 0 !important;
  list-style: none;
}
.list-category li{
  padding: 5px 5px 5px 5px;
  cursor: pointer;
  text-transform: capitalize;
  font-size: 13px;
  /* font-weight: 600; */
}

.readmore-font{
  font-size: 12px;
  font-weight: 500;
  /* line-height: 14px; */
}
.summery-text{
  font-size: 14px;
  float: left;
  margin-left: 5px;
}
.modal-body-addon{
  height: 100% !important;
}
.readmoreTextUp{
  position: relative;
  top: -10px;
}
.addonName {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
}
.readmore-position{
  position: relative;
  width: 100%;
}
.readmore-position p{
  width: 90%;
  margin-bottom: 5px;
}
.readmore-position .readmoreText{
  position: absolute;
  top: 5px;
  right: 30px;
}
.checkout-font {
  font-size: 14px !important;
  height: 35px !important;
  position: relative;
  /* right: 10px; */
  left: 53%;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
}
/* .mobile-menu-addon{
  display: none;
} */
.visible {
  display: block;
}
.hidden {
  display: none;
}
.addonArrayModal{
  margin-left: 38%;
  font-weight: 500;
}
button.category-menu-mobile {
  position: absolute;
  right: 50%;
}
.modal.fade .modal-dialog.modal-content-new{
  top: 30%;
    
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}
@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
p.cancel-text-head {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}
